


























































































































import { PagedResultModel } from '@/app_code/models/PagedResultModel'
import { ServiceManager } from '@/app_code/services'
import { View } from '@/app_code/services/meta/view'
import ServiceType from '@/app_code/services/ServiceTypes'
import UnitCategoryCard from '@/components/cards/UnitCategoryCard.vue'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { GetUnitModel } from '../app_code/models/GetUnitModel'
import { API } from '../app_code/services/api/API'
import Topic from '../components/Topic.vue'
import Loader from '../components/ui/Loader.vue'

@Component({
  components: {
    Topic,
    UnitCategoryCard,
    Loader
  }
})
export default class UnitLibrary extends Mixins(View) {
  private searchInput = ''
  private currentPage = 1
  private units: PagedResultModel<GetUnitModel[]> | null = null

  private category: string = this.$route.params.id

  private api = ServiceManager.Require<API>(ServiceType.API)

  mounted (): void {
    this.getUnits()
  }

  @Watch('currentPage')
  @Watch('searchInput')
  getUnits (): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.api.UnitsAPI.GetUnits(
        this.currentPage,
        this.searchInput,
        this.category
      )
        .then(data => {
          this.units = data
          resolve(true)
        })
        .catch(reject)
    })
  }

  goToPage (page: number): void {
    const element = this.$refs.table as HTMLDivElement
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    })
    if (page < 1) {
      this.currentPage = 1
    } else if (page > (this.units?.totalPages || this.currentPage)) {
      this.currentPage = this.units?.totalPages ?? 1
    } else {
      this.currentPage = page
    }
  }
}
